<template>
  <div class="promotion-event">
    <span
      id="container__product-price"
      class="product-price product-price--sale"
    >
      <transition name="fade">
        <span
          v-if="oldPriceConsiderPromotion && oldPriceConsiderPromotion > price"
          class="product-price__previous"
          >{{ formatPrice(oldPriceConsiderPromotion) }}</span
        >
      </transition>

      <transition name="fade">
        <span
          v-if="price"
          :class="[
            'lead',
            'price',
            'selling',
            {
              'product-price__current':
                oldPriceConsiderPromotion && oldPriceConsiderPromotion > price,
            },
          ]"
          >{{ formatPrice(price) }}</span
        >
      </transition>
    </span>
    <transition name="fade">
      <div v-if="inStock" class="promo-event">
        <promo-event-bar :progress="purchased" />
        <div class="promo-event__info">
          <span class="promo-event__left">
            {{ purchased }}% {{ $t('promotion.purchased') }}
          </span>
          <div class="promo-event__right">
            {{ $t('promotion.end_in') }}
            <span class="promo-event__end-in">{{ timer }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import priceable from '~/mixins/priceable'
import timer from '~/mixins/timer'
import PromoEventBar from '~/components/promo-event-bar/PromoEventBar'

export default {
  name: 'PromotionEvent',
  components: { PromoEventBar },
  mixins: [priceable, timer],
  props: {
    fromProductPage: {
      type: Boolean,
      default: false,
    },
    oldPrice: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    sold: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    inStock: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    oldPriceConsiderPromotion() {
      if (!this.oldPrice && this.fromProductPage) {
        return this.price
      }

      return this.oldPrice
    },
    purchased() {
      return Math.floor((this.sold / this.quantity) * 100).toFixed(0)
    },
  },
  created() {
    this.startTimer()
  },
}
</script>

<style lang="scss">
.promotion-event {
  display: flex;
  flex-direction: column;
}

.product-price {
  min-height: 26px;
}

.promo-event {
  margin-top: 2px;

  .promo-event__info {
    margin-top: 1px;
    font-size: 9px;
    text-align: right;
  }
  .promo-event__left {
    float: left;
  }

  @media (min-width: $v4_desktop) {
    .promo-event__info {
      font-size: 11px;
    }
  }
}
</style>
