<template>
  <img
    v-if="showBadgeImg"
    v-lazyload
    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
    :data-src="badge.image"
    style="height: 17px; width: 95px"
    alt="badge"
  />
  <span
    v-else-if="isPresent(badge)"
    class="product-badge badge__text"
    :style="{ color: badge.hex_color, 'border-color': badge.hex_color }"
  >
    {{ badge.label }}
  </span>
  <span v-else :class="noBadgeClass" />
</template>

<script>
import isPresent from '~/utils/isPresent'

export default {
  name: 'Badge',
  props: {
    badge: {
      type: [Object, Array],
      default: null,
    },
    catalogHasBadge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noBadgeClass() {
      return this.catalogHasBadge
        ? 'product-badge--nobadge'
        : 'possible--nobadge'
    },
    showBadgeImg() {
      return (
        isPresent(this.badge) &&
        isPresent(this.badge.image) &&
        !/\/missing\.png/i.test(this.badge?.image)
      )
    },
  },
  methods: {
    isPresent,
  },
}
</script>

<style lang="scss" scoped>
.product-badge {
  color: $v4_color_primary;
  display: inline-block;
  vertical-align: top;
  line-height: 15px;
  font-size: 11px;
  border-color: $v4_color_primary;
  border: 1px solid $v4_color_primary;
  border-radius: 3px;
  padding: 0 4px;
  background: #fff;
  margin-bottom: 0;
  margin-top: -1px;
  white-space: nowrap;
}
</style>
