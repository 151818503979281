<template>
  <div class="pre-sale">
    <modal
      data-modal="pre-sale"
      :content-class="'modal-velociraptor--presale'"
      :open="isModalOpen"
      @close="closeModal"
    >
      <template v-slot:title>{{ $t('pre_sale.modal_title') }}</template>

      <pre-sale-form
        :product-id="productId"
        :material="material"
        :case-device-id="caseDeviceId"
        :is-product-page="false"
        :type="type"
        @submitted="emitFormSubmitted"
        @close-pre-sale-modal="closeModal"
      />
    </modal>
  </div>
</template>

<script>
import Modal from '~/components/Modal'
import PreSaleForm from '~/components/product/form/PreSaleForm.vue'

export default {
  name: 'PreSaleModal',
  components: {
    Modal,
    PreSaleForm,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    caseDeviceId: {
      type: [Number, String],
      default: '',
    },
    material: {
      type: String,
      default: '',
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'shelfProduct',
    },
  },
  methods: {
    emitFormSubmitted() {
      this.$emit('form-submitted')
    },
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss">
.pre-sale {
  position: fixed;
  z-index: 1000;
}
.product__pre-sale--btn {
  color: #fff;
  background-color: #444;
  padding: 6px 3px 6px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 5px;
  cursor: pointer;
  text-transform: uppercase;
}
.modal-velociraptor__title {
  text-align: left;
}
.product__pre-sale--btn:hover {
  background-color: #333;
}
.pre-sale {
  .pre-sale__text {
    color: #666;
    margin: 0 0 6px;
    font-size: 12px;
    line-height: 1.35;
  }
}
@media (min-width: $screen_large) {
  .pre-sale {
    .pre-sale__text {
      font-size: 13px;
    }
  }
}
</style>
