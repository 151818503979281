var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"promotion-event"},[_c('span',{staticClass:"product-price product-price--sale",attrs:{"id":"container__product-price"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.oldPriceConsiderPromotion && _vm.oldPriceConsiderPromotion > _vm.price)?_c('span',{staticClass:"product-price__previous"},[_vm._v(_vm._s(_vm.formatPrice(_vm.oldPriceConsiderPromotion)))]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.price)?_c('span',{class:[
          'lead',
          'price',
          'selling',
          {
            'product-price__current':
              _vm.oldPriceConsiderPromotion && _vm.oldPriceConsiderPromotion > _vm.price,
          } ]},[_vm._v(_vm._s(_vm.formatPrice(_vm.price)))]):_vm._e()])],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.inStock)?_c('div',{staticClass:"promo-event"},[_c('promo-event-bar',{attrs:{"progress":_vm.purchased}}),_vm._v(" "),_c('div',{staticClass:"promo-event__info"},[_c('span',{staticClass:"promo-event__left"},[_vm._v("\n          "+_vm._s(_vm.purchased)+"% "+_vm._s(_vm.$t('promotion.purchased'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"promo-event__right"},[_vm._v("\n          "+_vm._s(_vm.$t('promotion.end_in'))+"\n          "),_c('span',{staticClass:"promo-event__end-in"},[_vm._v(_vm._s(_vm.timer))])])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }