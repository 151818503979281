export default {
  data() {
    return {
      timer: null,
      timerId: null,
    }
  },
  methods: {
    startTimer() {
      // Avoid start timer on server-side b/c it may cause memory leaks!
      if (process.server) {
        return
      }

      clearInterval(this.timerId)
      if (!this.endDate) return

      const vm = this
      const countDownDate = new Date(this.endDate).getTime()
      this.initialTimeValue(vm, countDownDate)

      this.timerId = setInterval(() => {
        this.initialTimeValue(vm, countDownDate)
      }, 1000)
    },
    formatTime(time) {
      return time.toString().length === 1 ? '0' + time : time
    },
    initialTimeValue(vm, countDownDate) {
      // Get today's date and time
      const now = new Date().getTime()

      // Find the distance between now and the count down date
      const distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      let stringDate = days ? `${days}d ` : ''
      stringDate += `${this.formatTime(hours)}:${this.formatTime(
        minutes
      )}:${this.formatTime(seconds)}`

      this.timer = stringDate

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(this.timerId)
        // TODO: Translate it!
        this.timer = ''
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timerId)
    this.timerId = null
  },
}
