<template>
  <div class="v-modal">
    <div
      :class="['v-modal__backdrop', { hide: !open }]"
      data-action="backdrop"
      @click="$emit('close')"
    ></div>
    <div
      v-bind="$attrs"
      :class="[
        'modal-velociraptor',
        { open: open, 'modal-velociraptor--fullscreen': fullScreen },
        contentClass,
      ]"
      :style="`max-width: ${maxWidth}px`"
    >
      <div class="modal-velociraptor__title">
        <!-- @slot Use for modal title-->
        <slot name="title"></slot>
        <div
          data-action="close"
          class="modal-velociraptor__close"
          @click="$emit('close')"
        >
          <cross-icon size="20px" />
        </div>
      </div>
      <div
        :class="[
          'modal-velociraptor__body',
          {
            'modal-velociraptor__body--fixed': fixedBtn,
          },
        ]"
      >
        <!-- @slot Use for modal body content-->
        <slot></slot>
      </div>
      <div v-if="showFooter" class="modal-velociraptor__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CrossIcon from '~/components/icons/cross/Cross.vue'
import modalable from '~/mixins/modalable'

export default {
  name: 'Modal',
  components: { CrossIcon },
  mixins: [modalable],
  inheritAttrs: false,
  props: {
    /** Whether modal is open or not */
    open: {
      type: Boolean,
      default: false,
    },
    /** Modal content element classes */
    contentClass: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '1100',
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
    fixedBtn: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    open(newBool) {
      this.modalIsOpened(newBool)
    },
  },
  mounted() {
    document
      .querySelector('.modal-velociraptor')
      ?.addEventListener('scroll', (e) => {
        const totalHeight =
          document.querySelector('.modal-velociraptor__body').clientHeight +
          document.querySelector('.modal-velociraptor__footer').clientHeight +
          document.querySelector('.modal-velociraptor__title').clientHeight

        const scrolledTop = document.querySelector('.modal-velociraptor')
          .scrollTop

        const scrollFinal = scrolledTop + window.innerHeight + 10

        if (scrollFinal > totalHeight) {
          document.querySelector('.modal-velociraptor').scrollTop =
            scrolledTop - 10
        }
      })
  },
}
</script>
<style lang="scss">
@import '~/assets/css/modal-velociraptor.scss';

.v-modal {
  z-index: 1000px;

  &__backdrop {
    background: #000;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}
</style>
