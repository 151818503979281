export default {
  methods: {
    modalIsOpened(bool) {
      if (bool) {
        document.body.classList.add('modal--opened')
      } else {
        document.body.classList.remove('modal--opened')
      }
    },
  },
}
