/**
 * Price helper to format and parse prices
 */

import isBlank from './isBlank'

/**
 * Format price according with locale and currency
 *
 * @param {Options} options.price price to be formatted
 * @param {Options} options.locale international locale e.g 'pt-BR'
 * @param {Options} options.currency ISO code currency e.g 'BRL'
 *
 * @returns {String} formatted price
 */
function format({ price, locale, currency }) {
  const _price = Number(price)
  if (isBlank(price) || Number.isNaN(_price) || !Number.isFinite(_price))
    return null

  const currencyParams = {
    style: 'currency',
    currency,
  }

  if (currency === 'AUD') {
    currencyParams.currencyDisplay = 'code'
  }

  return new Intl.NumberFormat(locale, currencyParams)
    .format(price)
    .replace(/\s/, '')
}

export default {
  format,
}
