/**
 * Ometria email methods
 *
 * Example:
 *  OmetriaEmail.set('adenilsonblog@gmail.com') set current session ometria e-mail
 *  OmetriaEmail.get() gets current session ometria email
 *  OmetriaEmail.delete() delete current session ometria e-mail
 *
 */

const emailKey = 'ometria_email'

export default class OmetriaEmail {
  static set(email) {
    sessionStorage.setItem(emailKey, email)
    return email
  }

  static get() {
    return sessionStorage.getItem(emailKey)
  }

  static delete() {
    sessionStorage.removeItem(emailKey)
    return this.get()
  }
}
