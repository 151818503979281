var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-modal"},[_c('div',{class:['v-modal__backdrop', { hide: !_vm.open }],attrs:{"data-action":"backdrop"},on:{"click":function($event){return _vm.$emit('close')}}}),_vm._v(" "),_c('div',_vm._b({class:[
      'modal-velociraptor',
      { open: _vm.open, 'modal-velociraptor--fullscreen': _vm.fullScreen },
      _vm.contentClass ],style:(("max-width: " + _vm.maxWidth + "px"))},'div',_vm.$attrs,false),[_c('div',{staticClass:"modal-velociraptor__title"},[_vm._t("title"),_vm._v(" "),_c('div',{staticClass:"modal-velociraptor__close",attrs:{"data-action":"close"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('cross-icon',{attrs:{"size":"20px"}})],1)],2),_vm._v(" "),_c('div',{class:[
        'modal-velociraptor__body',
        {
          'modal-velociraptor__body--fixed': _vm.fixedBtn,
        } ]},[_vm._t("default")],2),_vm._v(" "),(_vm.showFooter)?_c('div',{staticClass:"modal-velociraptor__footer"},[_vm._t("footer")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }