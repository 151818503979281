var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'product-image',
    { 'product-image--outofstock': !_vm.inStock && !_vm.isPreSale } ]},[(_vm.showSuddenPromo && false)?_c('img',{class:['product__sudden-promo-img', 'product__sudden-promo-img--brush'],attrs:{"src":require("assets/images/temporary-images/mimos_kit_pinceis.webp"),"alt":"Ganhe um Kit de Pinceis"}}):_vm._e(),_vm._v(" "),(_vm.showSuddenInfo)?_c('img',{class:['product__sudden-promo-img'],attrs:{"src":require("assets/images/temporary-images/mimos_marmita_etiqueta.webp"),"alt":"Ganhe uma Marmita"}}):_vm._e(),_vm._v(" "),(_vm.showSuddenGift)?_c('img',{class:['product__sudden-promo-img'],attrs:{"src":require("assets/images/temporary-images/tag-square-mimo.png"),"alt":"Ganhe uma Square"}}):_vm._e(),_vm._v(" "),(_vm.showSuddenBestPromo)?_c('img',{class:['product__sudden-promo-img', 'product__sudden-promo-img--best'],attrs:{"src":require("assets/images/temporary-images/melhor_promo_etiqueta.webp"),"alt":"Melhor Promo do Ano"}}):_vm._e(),_vm._v(" "),(_vm.productTag && ['all', 'listing'].includes(_vm.productTag.location))?_c('img',{class:['product__sudden-promo-img', 'product__sudden-promo-img--best'],attrs:{"src":_vm.optimizedTagImageUrl,"alt":"Melhor Promo do Ano"}}):_vm._e(),_vm._v(" "),_c('meta',{attrs:{"itemprop":"image","content":_vm.imageUrl}}),_vm._v(" "),(_vm.forceShowLoading || _vm.loadingImage)?_c('div',{class:[
      'product-image__thumbnail',
      'product-image__thumbnail--loading',
      {
        'product-image__thumbnail--skip-gray': _vm.skipGrayBg,
      } ]},[_c('logo-loading',{attrs:{"color":"#d7d7db"}})],1):_vm._e(),_vm._v(" "),_c('img',{directives:[{name:"srcset",rawName:"v-srcset",value:({ srcsetType: _vm.type, isMobile: true }),expression:"{ srcsetType: type, isMobile: true }"},{name:"lazyload",rawName:"v-lazyload"}],ref:"image",class:[
      'lazy',
      'product-image__thumbnail',
      'product-image__thumbnail--zero-opacity',
      {
        'product-image__thumbnail--loaded':
          !_vm.forceShowLoading && !_vm.loadingImage,
      } ],attrs:{"data-src":_vm.replacedImage || _vm.imageUrl,"title":_vm.image.alt,"alt":_vm.image.alt || 'Product Image',"display_mode":_vm.image.display_mode},on:{"mouseover":_vm.showGif,"mouseleave":_vm.showNormalImage,"load":_vm.onLoad,"error":_vm.onError}}),_vm._v(" "),(_vm.showSuddenPromo && false)?_c('b',{class:[
      'product__sudden-promo-text',
      'product__sudden-promo-text--small' ]},[_c('img',{attrs:{"src":require("assets/images/bolt.png"),"alt":"Despacho imediato icone"}}),_vm._v("\n    DESPACHO IMEDIATO")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }