<template>
  <div>
    <div v-if="!submitted" class="content--thinner panel-animation__primary">
      <div class="pre-sale-form">
        <h2 v-if="!isProductPage" class="pre-sale-form__title">
          {{ $t('pre_sale.form_title') }}
        </h2>
        <p>{{ $t('pre_sale.description') }}</p>
        <form
          id="pre-sale-form"
          ref="form"
          accept-charset="UTF-8"
          novalidate
          @submit.prevent="onSubmit"
        >
          <input name="utf8" type="hidden" value="✓" />
          <div class="pre-sale__section">
            <input
              id="pre_sale_email"
              v-model="formData.email"
              type="email"
              :placeholder="$t('pre_sale.your_email')"
              name="email"
              :class="[
                'pre-sale-form__email',
                { error: errors.email.length > 0 },
              ]"
              required
            />
            <div v-if="errors.email.length > 0" class="form__field-error">
              {{ errors.email.join(' ') }}
            </div>
            <div class="hint">
              {{ $t('pre_sale.email_hint') }}
            </div>
          </div>
          <!-- Display phone input only for BR Store -->
          <div v-if="$store.state.storeCode === 'br'" class="pre-sale__section">
            <input
              id="pre_sale_phone_number"
              v-model="formData.phone"
              v-mask="'(##) # ####-####'"
              type="text"
              name="phone_number"
              :placeholder="$t('pre_sale.your_phone')"
              :class="['pre-sale-form__phone_number']"
            />
            <div class="hint">
              {{ $t('pre_sale.phone_hint') }}
            </div>
          </div>
          <div id="pre_sale_hidden_tags" class="hide">
            <input
              id="pre_sale_material"
              type="text"
              name="material"
              :value="material"
            />
            <input
              id="pre_sale_product_id"
              type="text"
              name="product_id"
              :value="productId"
            />
            <input
              id="pre_sale_case_device_id"
              type="text"
              name="case_device_id"
              :value="caseDeviceId"
            />
            <input
              id="pre_sale_store"
              type="text"
              name="store"
              :value="$store.state.storeCode"
            />
            <input
              id="pre_sale_locale"
              type="text"
              name="locale"
              :value="$store.state.locale"
            />
            <input
              id="pre_sale_enabled"
              type="text"
              name="pre_sale"
              :value="true"
            />
          </div>
          <label
            for="pre_sale_subscribe_to_newsletter"
            class="pre-sale__section pretty-checkbox pretty-checkbox--tiny"
          >
            <input
              id="pre_sale_subscribe_to_newsletter"
              type="checkbox"
              name="subscribe_to_newsletter"
              value="1"
              class="bundle__checkbox"
              checked="checked"
            />
            <div class="pretty-checkbox__check"></div>
            <span>{{ $t('pre_sale.newsletter') }}</span>
          </label>
          <button
            id="alert-me-when-stock-is-back-button"
            name="button"
            type="submit"
            class="btn btn--large btn--block"
            :disabled="isSubmiting || submitted"
            :aria-disabled="isSubmiting"
            data-testeid="submit"
          >
            <wheel-loading v-if="isSubmiting" color="white" size="22px" />
            <template v-else>
              {{
                submitted ? $t('pre_sale.success_msg') : $t('pre_sale.submit')
              }}
            </template>
          </button>
        </form>
      </div>
    </div>
    <div v-if="submitted" class="content--thinner panel-animation__secundary">
      <div class="check-animation section">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="640"
          height="640"
          viewBox="0 0 640 640"
        >
          <title></title>
          <g id="icomoon-ignore"></g>
          <path
            d="M176 560c-4.095 0-8.189-1.562-11.314-4.687l-160-160c-6.248-6.248-6.248-16.379 0-22.627s16.379-6.248 22.628 0l148.686 148.686 436.687-436.686c6.248-6.248 16.379-6.248 22.627 0s6.249 16.379 0 22.628l-448 448c-3.125 3.124-7.219 4.686-11.314 4.686z"
          ></path>
        </svg>
      </div>
      <h2 class="panel-animation__title center">
        {{ $t('pre_sale.success_title') }}
      </h2>
      <p class="pre-sale__section section--bigger">
        {{ $t('pre_sale.success_msg') }}
      </p>
      <button
        type="button"
        class="btn btn--block modal-velociraptor__hide"
        data-modal="stock-alert"
        @click.prevent="continuePurchasing"
      >
        {{ $t('keep_shopping') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import isBlank from '~/utils/isBlank'
import validateEmail from '~/commun/utils/validateEmail'
import OmetriaEmail from '~/utils/OmetriaEmail'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import WheelLoading from '~/components/loadings/wheel'

export default {
  name: 'PreSaleForm',
  components: { WheelLoading },
  directives: { mask },
  mixins: [pushDataLayer],
  props: {
    productId: {
      type: Number,
      required: true,
    },
    caseDeviceId: {
      type: [Number, String],
      default: '',
    },
    material: {
      type: String,
      default: '',
    },
    isProductPage: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'shelfProduct',
    },
  },
  data() {
    return {
      isSubmiting: false,
      submitted: false,
      formData: {
        email: '',
        phone: '',
      },
      errors: {
        email: [],
        phone: [],
      },
    }
  },
  methods: {
    onSubmit() {
      if (this.validateInputs()) {
        this.isSubmiting = true
        this.$axios
          .$post('/stock_alert', this.getFormData())
          .then(this.onSuccess)
          .catch(this.onError)
          .finally(() => {
            this.isSubmiting = false
          })
      } else {
        this.$displayTopMessage(this.$t('pre_sale.check_errors'), {
          type: 'error',
        })
      }
    },
    validateInputs() {
      this.errors.email = []

      if (isBlank(this.formData.email)) {
        this.errors.email.push(this.$t('product_form.errors.required'))
      }

      if (!validateEmail(this.formData.email)) {
        this.errors.email.push(
          this.$t('product_form.errors.invalid_email', {
            email: this.formData.email,
          })
        )
      }

      return this.errors.email.length === 0
    },
    getFormData() {
      return new FormData(this.$refs.form)
    },
    onSuccess() {
      this.submitted = true
      this.$emit('submitted')

      OmetriaEmail.set(this.formData.email)

      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'email',
        eventAction: 'captureEmail',
        eventValue: this.formData.email,
      })

      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'preSaleForm',
        eventAction: 'submit',
        eventValue: {
          productId: this.productId,
          caseDeviceId: this.caseDeviceId,
          material: this.material,
          type: this.isProductPage ? 'productPage' : this.type,
        },
      })
    },
    onError() {
      this.$displayTopMessage(this.$t('smt_went_wrong'), {
        type: 'error',
      })
    },
    continuePurchasing() {
      this.isProductPage ? this.scrollIntoShelf() : this.closeModal()
    },
    scrollIntoShelf() {
      document
        .querySelector('.shelf.shelf--no-crossell')
        ?.scrollIntoView({ behavior: 'smooth' })
    },
    closeModal() {
      this.$emit('close-pre-sale-modal')
    },
  },
}
</script>
<style lang="scss">
@import '~/assets/css/check-animation.scss';
@import '~/assets/css/panel-animation.scss';
@import '~/assets/css/hint.scss';
@import '~/assets/css/pretty-checkbox.scss';

.pre-sale-form__title {
  font-size: 32px;
  color: #333;
  margin: 0 0 30px;
}
.pre-sale-form__hint {
  margin: 10px 0px;
  text-align: justify;
}
.pre-sale-form__email,
.pre-sale-form__phone_number {
  max-width: 640px;
  margin: 0 auto;
}
.pre-sale__section {
  text-align: center;
}
.pre-sale__section .hint {
  text-align: left;
}
.pre-sale__section {
  justify-content: left;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 20px;
}
.pre-sale__section span {
  text-align: left;
}
</style>
