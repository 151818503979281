import Price from '../utils/price'

export default {
  methods: {
    formatPrice(price, options = {}) {
      let locale = options.locale || this.$store.state.locale
      const currency = options.currency || this.$store.state.currency

      if (!['pt-BR', 'en'].includes(locale)) locale = 'en' // Intl.NumberFormat just know the global currencies if is 'en' locale

      return Price.format({ currency, locale, price })
    },
    parseNumber(number) {
      const n = parseFloat(number)

      return Number.isNaN(n) ? '' : n
    },
  },
}
